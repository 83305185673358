import React from 'react'
import { RoadMapTablesProps, useRenderRoadmapTables } from './table'
import { chain, Flex, Text } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { AddRoadmapMoreBarAction } from '@src/pages/Forms/RoadmapForm/common/AddRoadmapMoreBarAction'
import { EntityTypes } from '@src/constants/api'
import { RoadmapActionsBar, useRoadmapRefresh } from '.'
import RoadmapLockedChangesBanner from '../Banners/RoadmapLockedChangesBanner'
import { RoadmapSettingsInterface } from '@src/interfaces/settings'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { TableNames } from '@src/constants/table'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'

export interface RoadmapTabProps extends RoadMapTablesProps {
  allowRefresh: boolean
  addRoadmapProps: {
    entityType: EntityTypes
    id: number
    backUrl: string
    name: string
    tags?: string[]
  }
  triggerRefresh: (cycleId?: string | number) => Promise<void>
  tableInfoMessage?: React.ReactNode
  roadmapSettings?: RoadmapSettingsInterface
  showTableInfo: boolean
  newLayoutNavigation?: React.ReactElement
}

export const RoadmapTab = (props: RoadmapTabProps) => {
  const {
    addRoadmapProps,
    triggerRefresh,
    tableInfoMessage,
    roadmapSettings,
    allowRefresh,
    showTableInfo,
    newLayoutNavigation,
    ...tableOuterProps
  } = props
  const { allowEdit, reviewCycle } = tableOuterProps
  const { table, changelogSwitcher, changelogState, renderTable } =
    useRenderRoadmapTables(tableOuterProps)
  const isNewLayout = useIsNewLayout()
  const isNewTable = useIsNewTable()

  const { renderRefreshNote, refreshButton, pendingTaskId } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const { data: settings } = useGetRoadmapSettings()
  const moreBarPending = !roadmapSettings || !props.reviewCycle

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Flex width="100%" justifyContent="space-between">
          <Flex gap="s-32">
            {isNewTable ? null : isNewLayout &&
              addRoadmapProps.entityType === EntityTypes.employee ? (
              <PerformanceLayoutCycleFilter
                onFilterChange={table.onFilterChange}
                columnName="review_cycle__offset"
                filter={table.filterBy}
              />
            ) : (
              <CycleFilter
                type={CycleFilterType.NewUI}
                onFilterChange={table.onFilterChange}
                columnName="review_cycle__offset"
                filter={table.filterBy}
                filterInputType={FilterSelectType.SingleSelect}
              />
            )}
            <Stat
              label={
                isNewTable
                  ? chain(
                      'Progress',
                      allowRefresh &&
                        renderRefreshNote({
                          lastUpdated: table?.stats?.refresh_date_time,
                          chained: true,
                        }),
                    )
                  : 'Progress'
              }
              val={
                table?.stats?.avg_done !== undefined ? (
                  <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                    {formatPercentage(table?.stats?.avg_done)}
                  </Text>
                ) : (
                  'N/A'
                )
              }
            />
            {!isNewTable && allowRefresh && (
              <Flex alignItems="flex-end">
                {renderRefreshNote({ lastUpdated: table?.stats?.refresh_date_time })}
              </Flex>
            )}
          </Flex>
          {!!newLayoutNavigation &&
            ((isNewLayout && props.tableProps.name === TableNames.EmployeeRoadmap) ||
              props.tableProps.name === TableNames.DepartmentRoadmap) &&
            newLayoutNavigation}
        </Flex>
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <RoadmapActionsBar pending={moreBarPending}>
          {allowEdit && (
            <AddRoadmapMoreBarAction
              {...addRoadmapProps}
              onAfterSubmit={() => table.refresh()}
              reviewCycle={reviewCycle || null}
            />
          )}
          {changelogSwitcher}
          {allowRefresh && refreshButton}
          {settings?.jira_epics_enabled ? (
            <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
          ) : null}
        </RoadmapActionsBar>
      </Table.Widget.Actions>
      <RoadmapLockedChangesBanner
        hidden={!changelogState.active}
        pb="s-8"
        reviewCycle={reviewCycle || null}
      />
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        {isNewTable && (
          <CycleFilterButton
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        )}
      </Table.Widget.Filters>
      <Table.Widget.Table>
        {renderTable({
          loading: !!pendingTaskId,
          emptyState: (
            <EmptyTableRaw
              title={
                <FormattedMessage
                  defaultMessage="Your roadmap goes here"
                  id="performance.roadmaps.emptyTable.title"
                />
              }
              description={
                <FormattedMessage
                  defaultMessage="All your epics will appear here"
                  id="performance.roadmaps.emptyTable.description"
                />
              }
              action={
                allowEdit ? (
                  <AddRoadmapMoreBarAction
                    {...addRoadmapProps}
                    variant="text"
                    onAfterSubmit={() => table.refresh()}
                    reviewCycle={reviewCycle || null}
                  />
                ) : null
              }
            />
          ),
        })}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
