import React from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { Text } from '@revolut/ui-kit'

const StyledSubtitle = styled(Text)`
  p {
    margin: 0;
  }

  // When you nest ul/ol inside a li it will naturally indent.
  // Ql-editor uses a different approach by adding .ql-indent-x classes to indented li
  // using css.
  // Content from ql-editor will be indented inside ql-editor but outside it will render
  // like a single level list.
  // This css is the minimal css that is needed to make sure that lists will be nested up
  // to 9 levels like ql-editor.
  ul,
  ol {
    padding: 0 0 0 40px;
    margin: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
    list-style-type: none;
  }
  // Here we have to override the default ul li bullet because if we don't it will render
  // the bullet before the padding we give in each li.ql-indent-x.
  // This way the bullets are also nested with the content
  ul li {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
  ol li:before {
    content: counter(list-0, decimal) '. ';
  }
  li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
    padding-left: 4.5em;
  }
  ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }
  li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-2;
    padding-left: 7.5em;
  }
  ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }
  li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-3;
    padding-left: 10.5em;
  }
  ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }
  li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-4;
    padding-left: 13.5em;
  }
  ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }
  li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
    counter-increment: list-5;
    padding-left: 16.5em;
  }
  ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }
  li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
    counter-increment: list-6;
    padding-left: 19.5em;
  }
  ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }
  li.ql-indent-7 {
    counter-reset: list-8 list-9;
    counter-increment: list-7;
    padding-left: 22.5em;
  }
  ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }
  li.ql-indent-8 {
    counter-reset: list-9;
    counter-increment: list-8;
    padding-left: 25.5em;
  }
  ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }
`

type Props = {
  value?: string | React.ReactNode
}

const SectionSubtitle = ({ value }: Props) => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return (
      <StyledSubtitle
        use="div"
        variant="body2"
        color="grey-tone-50"
        style={{ wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
      />
    )
  }

  return (
    <Text
      use="div"
      variant="body2"
      color="grey-tone-50"
      style={{ wordBreak: 'break-word' }}
    >
      {value}
    </Text>
  )
}

export default SectionSubtitle
