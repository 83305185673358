import React from 'react'
import { Flex, HStack, Icon, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationStats } from '@src/api/totalCompensation'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'
import { PieChart } from '../Charts/PieChart'

// TODO: RHR-5935 replace hardcoded data with settings values when implementing on BE
const CURRENCY_ISO_CODE = 'GBP'
const SHARE_VALUE = 440.61

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const EquityTotal = ({ compensationParams, id }: Props) => {
  const { currency, setCurrency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationStats(id, filters)

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Vested',
      value: data ? Number(data.total_vested_equity) : 0,
    },
    {
      name: 'Unvested',
      value: data ? Number(data.total_unvested_equity) : 0,
      color: Token.color.greyTone50,
    },
  ]

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height="50vh"
      gap="s-16"
      data-testid="total_equity_section"
    >
      <Widget flex={1} p="s-16">
        <Flex alignItems="stretch" flexDirection="column" height="100%">
          <Flex alignItems="center" gap="s-4">
            <Text variant="emphasis2">Total equity</Text>
            <Tooltip
              placement="bottom"
              text="The total value of your equity, including both vested and unvested shares, based on the latest share price valuation."
            >
              <Icon name="InfoOutline" size={16} />
            </Tooltip>
          </Flex>

          <CurrencySelect
            label=""
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            value={data?.currency.iso_code}
          />

          <PieChart
            currency={data?.currency.iso_code}
            data={pieChartData}
            isLoading={isLoading}
            label="Total"
            total={data ? Number(data.total_equity) : undefined}
          />
        </Flex>
      </Widget>

      <Widget p="s-16">
        <HStack gap="s-4" mb="s-16">
          <Text variant="emphasis2">Share value</Text>
          <Tooltip
            placement="bottom"
            text="The current market value of each share of your equity."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <Flex alignItems="center" flexDirection="column">
          <Text variant="heading2">{formatMoney(SHARE_VALUE, CURRENCY_ISO_CODE)}</Text>
          <Text color={Token.color.greyTone50} variant="body2">
            Estimate
          </Text>
        </Flex>
      </Widget>
    </Flex>
  )
}
