import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Item,
  MoreBar,
  Text,
  Token,
  VStack,
  useToggle,
  Widget,
  Box,
} from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { useFetchInterviewAvailability } from '@src/api/interviewAvailability'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getCurrentWeek, NavigationWeek } from '@src/components/NavigateWeek/NavigateWeek'
import { InterviewAvailabilityPreferencesSidebar } from '@src/features/InterviewAvailability/InterviewAvailabilityPreferencesSidebar'
import { InterviewAvailabilitySyncAction } from '@src/features/InterviewAvailability/InterviewAvailabilitySyncAction'
import { useGetEmployeePersonalSettings } from '@src/api/employees'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { GOOGLE_CALENDAR, MICROSOFT_CALENDAR } from '@src/constants/externalLinks'
import { RequiresFeatureFlagWrapper } from '@src/components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'
import { LargeWeeklyCalendar } from '@components/LargeWeeklyCalendar'
import { getInterviewAvailabilityStatusText } from '@src/features/InterviewAvailability/InterviewAvailabilityStatus'

const getWeekRangeFilter = (week: NavigationWeek) => {
  const range = `${week.start},${week.end}`
  return [
    {
      filters: [{ id: range, name: range }],
      columnName: 'event_start_datetime',
    },
  ]
}

export const InterviewAvailabilityBanners = () => {
  const user = useSelector(selectUser)
  const { data, isLoading } = useGetEmployeePersonalSettings({
    refetchOnWindowFocus: true,
  })
  const isCalendarIntegrationEnabled = data?.calendar_integration_enabled
  if (!isLoading && !isCalendarIntegrationEnabled) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="CalendarCheck" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.title"
              defaultMessage="Integrate your Calendar"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.description"
              defaultMessage="Your calendar is not connected at the moment. Connect it to see your weekly meetings load."
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            target="_blank"
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, { id: user.id })}
            use={InternalLink}
          >
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.connectNow"
              defaultMessage="Connect now"
            />
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  if (!isLoading) {
    const calendarLink =
      data?.office_suite_provider === 'google' ? GOOGLE_CALENDAR : MICROSOFT_CALENDAR
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="InfoOutline" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.title"
              defaultMessage="How to create and manage interview slots"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.description"
              defaultMessage="To create interview slot, navigate to your calendar and create an event with a phrase {interviewSlot} in the title or description of the event. You can create your interview slots as recurring or singular meetings. To manage the interview slot simply change or delete the event from your calendar."
              values={{
                interviewSlot: (
                  <>
                    “
                    <Text color={Token.color.green} style={{ whiteSpace: 'nowrap' }}>
                      [interview-slot]
                    </Text>
                    ”
                  </>
                ),
              }}
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton target="_blank" href={calendarLink} use="a">
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.openCalendar"
              defaultMessage="Open calendar"
            />
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  return null
}

export const InterviewAvailabilityTablePage = () => {
  const [openInterviewAvailabilityPreferences, toggleInterviewAvailabilityPreferences] =
    useToggle()
  const employee = useSelector(selectUser)
  const employeeId = employee.id
  const [week, setWeek] = useState<NavigationWeek>(getCurrentWeek())
  const { data, refetch } = useFetchInterviewAvailability({
    filters: [
      {
        columnName: 'employee',
        filters: [{ id: employeeId, name: String(employeeId) }],
        nonResettable: true,
      },
      ...getWeekRangeFilter(week),
    ],
    sortBy: [
      {
        sortBy: 'event_start_datetime',
        direction: SORT_DIRECTION.DESC,
      },
    ],
  })

  const events = useMemo(
    () =>
      data?.results.map(item => ({
        start: item.event_start_datetime,
        end: item.event_end_datetime,
        title: getInterviewAvailabilityStatusText(item.status),
        backgroundColor:
          item.status !== 'pending' ? Token.color.greyTone8 : Token.color.actionBlue,
        color:
          item.status !== 'pending' ? Token.color.greyTone50 : Token.color.foreground,
      })) || [],
    [data?.results],
  )

  return (
    <>
      {openInterviewAvailabilityPreferences && (
        <InterviewAvailabilityPreferencesSidebar
          employeeId={employeeId}
          onClose={() => {
            toggleInterviewAvailabilityPreferences()
          }}
          onSuccess={() => {
            refetch()
          }}
        />
      )}
      <PageWrapper>
        <PageHeader
          backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
          title={
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.title"
              defaultMessage="Interview availability"
            />
          }
          subtitle={
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.subtitle"
              defaultMessage="Specify weekly time slots when you prefer to take interviews"
            />
          }
        />
        <PageBody maxWidth="100%">
          <VStack gap="s-16">
            <InterviewAvailabilityBanners />
            <Widget p="s-16">
              <MoreBar>
                <RequiresFeatureFlagWrapper
                  flags={[FeatureFlags.ApplyInterviewsAvailabilityLimitsToSlots]}
                >
                  <MoreBar.Action
                    useIcon="Gear"
                    onClick={() => {
                      toggleInterviewAvailabilityPreferences()
                    }}
                  >
                    <FormattedMessage
                      id="recruitment.interviewAvailability.actions.availabilityPreferences"
                      defaultMessage="Availability preferences"
                    />
                  </MoreBar.Action>
                </RequiresFeatureFlagWrapper>
                <InterviewAvailabilitySyncAction
                  employeeId={employeeId}
                  onSuccess={() => {
                    refetch()
                  }}
                />
              </MoreBar>

              <Box height="90vh" width="100%" overflow="auto" mt="s-16">
                <LargeWeeklyCalendar
                  startDayOfWeek={1}
                  eventView={['time']}
                  events={events}
                  onSwitchWeek={(start, end) => {
                    setWeek({
                      start: start.toISOString(),
                      end: end.toISOString(),
                    })
                  }}
                  disabled
                />
              </Box>
            </Widget>
          </VStack>
        </PageBody>
      </PageWrapper>
    </>
  )
}
