import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import { getVisibleSections } from '@src/utils/performance'
import {
  CardTypes,
  ChangeScorecardInterface,
  CriteriaAssessment,
  PerformanceReviewTypes,
  ReviewCategory,
  ScorecardError,
} from '@src/interfaces/performance'
import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'

type Props = {
  sections: CriteriaAssessment[]
  name: string
  title: string
  isViewMode?: boolean
  subTitle?: string
  notes?: string
  skills?: OptionInterface[]
  index: number
  titleIndexNumber?: number
  errors?: ScorecardError
}

export const LevelsCard = ({
  sections,
  name,
  title,
  subTitle,
  isViewMode,
  notes,
  skills,
  index,
  errors,
  titleIndexNumber,
}: Props) => {
  const [showErrorLabel, setShowErrorLabel] = useState(false)
  const { change, isSubmitting } = useLapeContext<InterviewFeedbackInterface>()

  const onChange = async ({
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    change(`${name}.${sectionIndex}.items.${optionIndex}.value`, value)
    setShowErrorLabel(false)
  }

  useEffect(() => {
    if (!isSubmitting) {
      setShowErrorLabel(true)
    }
  }, [isSubmitting])

  return (
    <ScorecardSection
      mt={0}
      mb={0}
      skillId="0"
      cardIndex={index}
      cardTitleNumber={titleIndexNumber}
      onChange={onChange}
      onSetResultLoading={() => {}}
      card={{
        name: title,
        sub_title: subTitle,
        type: CardTypes.skillCard,
        sections: getVisibleSections(sections),
        justification: notes,
        rating_expectation: null,
      }}
      type={PerformanceReviewTypes.interviewScorecard}
      category={ReviewCategory.Performance}
      hideResult
      viewMode={isViewMode}
      cardTitleTags={skills}
      errors={errors}
      touched
      generateOptionDataName={(cardIndex, sectionIndex, optionIndex) =>
        `scorecard.sections.${cardIndex}.levels_questions.${sectionIndex}.questions.${optionIndex}.answer`
      }
      showErrorLabel={showErrorLabel}
      noWidgetMargin
    />
  )
}
