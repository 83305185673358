import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CommonActions = ({ cycle }: Props) => {
  const { id, offset } = cycle

  const handlePreviewScorecardClick = () =>
    navigateTo(pathToUrl(ROUTES.PERFORMANCE.SCORECARD_PREVIEW, { id }))

  const handlePreviewPerformanceSummaryClick = () =>
    navigateTo(
      pathToUrl(
        ROUTES.PERFORMANCE.REVIEWS_CALIBRATION,
        {},
        offset !== undefined ? { cycle__offset: offset.toString() } : {},
      ),
    )

  return (
    <>
      <MoreBar.Action useIcon="EyeShow" onClick={handlePreviewScorecardClick}>
        Preview scorecard
      </MoreBar.Action>
      <MoreBar.Action
        useIcon="16/ListBullet"
        onClick={handlePreviewPerformanceSummaryClick}
      >
        Performance summary
      </MoreBar.Action>
    </>
  )
}
