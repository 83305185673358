import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { RoleInterface } from '@src/interfaces/roles'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { OrderingDataType } from '@src/interfaces/ordering'

interface Props {
  data: RoleInterface
}

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
}

export const AnalyticsDashboards = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [
        {
          name: OrderingDataType.Roles,
          id: OrderingDataType.Roles,
        },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.role}
      row={row}
      initialFilter={initialFilter}
      initialSort={initialSortBy}
    />
  )
}
