import React, { useEffect, useState } from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { VStack } from '@revolut/ui-kit'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { StageWidget } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/StageWidget'
import { ProgressWidget } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/ProgressWidget/ProgressWidget'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CycleTimeline = ({ cycle }: Props) => {
  const currentStage = TimelineModel.getCurrentStage(cycle)
  const stages = TimelineModel.getAvailableStages()

  const [selectedStage, setSelectedStage] = useState(currentStage)

  useEffect(() => {
    setSelectedStage(currentStage)
  }, [currentStage])

  const makeStageClickHandler = (stage: ReviewCycleStage) => () => {
    setSelectedStage(stage)
  }

  return (
    <VStack space="s-8">
      <ProgressWidget
        cycle={cycle}
        stages={stages}
        selectedStage={selectedStage}
        handleSelectionChange={makeStageClickHandler}
      />
      <StageWidget cycle={cycle} stage={selectedStage} />
    </VStack>
  )
}
