import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { connect } from 'lape'
import { getBackUrl, navigateReplace } from '@src/actions/RouterActions'
import {
  createRelatedCompany,
  createRelatedDepartments,
  createRelatedTeams,
  createRelatedEmployees,
  createRelatedGoals,
  createRelatedFunctions,
  createRelatedRoles,
  createRelatedSpecialisations,
} from '@src/api/analyticsDashboards'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { pathToUrl } from '@src/utils/router'
import {
  WithEntity,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'

interface SaveButtonProps {
  tabs: TabBarTableNavigationInterface[]
}

type LocationState = WithEntity<{
  backUrl: string
  state: WithEntity<{ backUrl: string }>
}>

export const SubmitButton = connect(({ tabs }: SaveButtonProps) => {
  const location = useLocation<LocationState>()
  const { values, submit } = useLapeContext<AnalyticsDashboardInterface>()
  const { navigateReplaceWithEntity } = useOrgEntity()

  const tabIndex = tabs.findIndex(tab => matchPath(location.pathname, tab.path!))
  const isLastTab = tabIndex >= tabs.length - 1

  if (!isLastTab) {
    return null
  }

  const afterSubmit = (result: { id?: number | string }) => {
    if (values.related_teams?.length) {
      createRelatedTeams(result.id!, values.related_teams[0].id)
    } else if (values.related_departments?.length) {
      createRelatedDepartments(result.id!, values.related_departments[0].id)
    } else if (values.related_employees?.length) {
      createRelatedEmployees(result.id!, values.related_employees[0].id)
    } else if (values.related_functions?.length) {
      createRelatedFunctions(result.id!, values.related_functions[0].id)
    } else if (values.related_roles?.length) {
      createRelatedRoles(result.id!, values.related_roles[0].id)
    } else if (values.related_specialisations?.length) {
      createRelatedSpecialisations(result.id!, values.related_specialisations[0].id)
    } else {
      createRelatedCompany(result.id!)
    }
    if (values.related_goals?.length) {
      createRelatedGoals(result.id!, values.related_goals[0].id)
    }
  }

  return (
    <NewSaveButtonWithPopup
      hideWhenNoChanges={false}
      onAfterSubmit={(res: AnalyticsDashboardInterface) => {
        if (!values.id && res.id) {
          afterSubmit(res)
        }

        const { pathname } = getBackUrl()

        if (matchPath(pathname, ROUTES.FORMS.GOAL.EDIT)) {
          navigateReplaceWithEntity(pathname)
        } else {
          navigateReplace(
            pathToUrl(
              res.dashboard_type === 'looker'
                ? ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD
                : ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD_VIEW,
              { id: res.id },
            ),
          )
        }
      }}
      onClick={submit}
      successText={
        values.id ? 'Dashboard successfully updated' : 'Dashboard successfully added'
      }
      useValidator
    >
      Confirm
    </NewSaveButtonWithPopup>
  )
})
