import {
  GradeLabelMappingInterface,
  PerformanceRating,
  Ratings,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { useMemo } from 'react'
import { deliverableOptionToPerformanceRating } from '@src/interfaces/performance'
import { getFinalGradeTitleFromRating } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { isObject } from 'lodash'
import { GradesMapInterface } from '@src/utils/grades'

export interface CalculatedDeliverablesRating {
  rating: PerformanceRating | undefined | null
  ratings: Ratings[] | undefined
  mapper?: (
    gradesMap: GradesMapInterface,
    rating?: PerformanceRating | null,
  ) => string | undefined
  gradesMap?: GradeLabelMappingInterface
}

export const useCalculatedDeliverablesRating = (
  summary: ReviewSummaryDataInterface | null | undefined,
  gradesMap?: GradeLabelMappingInterface,
) => {
  return useMemo(() => {
    const hasKPI = summary?.kpis_section

    if (hasKPI) {
      return {
        rating: deliverableOptionToPerformanceRating(
          summary?.kpis_section?.recommended_rating,
        ),
        ratings: summary?.kpis_section?.recommended_ratings,
      }
    }

    if (summary?.deliverables) {
      return {
        rating: summary?.deliverables?.rating,
        ratings: summary?.deliverables?.ratings,
        mapper: getFinalGradeTitleFromRating,
        gradesMap,
      }
    }

    const recommendedRating = summary?.calculated_deliverables_ratings?.recommended_rating
    const rating = isObject(recommendedRating)
      ? recommendedRating.rating
      : recommendedRating

    const ratings = isObject(recommendedRating)
      ? recommendedRating?.ratings
      : summary?.calculated_deliverables_ratings?.recommended_ratings

    return {
      rating,
      ratings,
    }
  }, [
    summary?.calculated_deliverables_ratings,
    summary?.kpis_section,
    summary?.deliverables,
  ])
}
