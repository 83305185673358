import React from 'react'
import { Box } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import SettingsLandingPage from '@src/pages/Settings/SettingsLandingPage/SettingsLandingPage'
import { Route, Switch } from 'react-router-dom'
import { GeneralSettings } from '@src/pages/Settings/General'
import { EmployeeSettings } from '@src/pages/Settings/Employees'
import { GroupsSettings } from '@src/pages/Settings/Groups'
import { LifecycleSettings } from '@src/pages/Settings/Lifecycle'
import { SecuritySettings } from '@src/pages/Settings/Security'
import { OrganisationSettings } from '@src/pages/Settings/Organisation'
import { FunctionSettings } from '@src/pages/Settings/Function'
import { KPISettings } from '@src/pages/Settings/KPI'
import { AccountsSettings } from '@src/pages/Settings/Accounts'
import { PerformanceSettings } from '@src/pages/Settings/Performance'
import { EngagementSettings } from '@src/pages/Settings/Engagement'
import { TimeOffSettings } from '@src/pages/Settings/TimeOff'
import { CommunicationSettings } from '@src/pages/Settings/Communication'
import { DocumentsSettings } from '@src/pages/Settings/Documents'
import { ContractsSettings } from '@src/pages/Settings/Contracts'
import { RoadmapSettings } from '@src/pages/Settings/Roadmaps'
import { EntitiesSettings } from '@src/pages/Settings/Entities'
import { LocationSettings } from '@src/pages/Settings/Locations'
import { SkillSettings } from '@src/pages/Settings/Skills'
import { AccessManagementSettings } from '@src/pages/Settings/AccessManagement'
import { TodoSettings } from '@src/pages/Settings/Todo'
import { RequisitionsSettings } from '@src/pages/Settings/Requisitions'
import { CandidatesSettings } from '@src/pages/Settings/Candidates'
import { JobPostingSettings } from '@src/pages/Settings/JobPosting'
import { ScreeningSettings } from '@src/pages/Settings/Screening'
import { CareerPageOnboardingSettings } from '@src/pages/Settings/JobPosting/CareerPage'
import { PayrollSettings } from '@src/pages/Settings/Payroll'
import { KeyPersonsSettings } from '@src/pages/Settings/KeyPersons'
import { HelpCenterSettings } from '@src/pages/Settings/HelpCenter'
import { UserPreferences } from '@src/pages/Settings/UserPreferences/UserPreferences'
import { CloseAccount } from '@src/pages/Settings/CloseAccount/CloseAccount'
import Page404 from '@src/pages/Page404/Page404'
import { BenefitsSettings } from '@src/pages/Settings/Benefits'
import { AttendanceSettings } from '@src/pages/Settings/Attendance'
import { PlanManagementSettings } from '@src/pages/Settings/PlanManagement'
import { CompensationSettings } from '@src/pages/Settings/Compensation'
import { CustomFieldsSettings } from '@src/pages/Settings/CustomFields'
import { GoalsSettings } from '@src/pages/Settings/Goals/Goals'
import { AnalyticsSettings } from '@src/pages/Settings/Analytics'
import { JobsSettings } from './Jobs'
import { CareersIntegrationsPage } from '@src/pages/Settings/JobPosting/CareerPage/CareersIntegrations'
import { OnboardingSettings } from '@src/pages/Settings/Onboarding'
import { SandboxModeSettings } from '@src/pages/Settings/SandboxMode'

const Settings = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={[ROUTES.SETTINGS.ALL, ROUTES.SETTINGS.INTEGRATIONS]}
          component={SettingsLandingPage}
        />
        <Route exact path={ROUTES.SETTINGS.SANDBOX_MODE}>
          <SandboxModeSettings />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.ANY}>
          <AccountsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ATTENDANCE}>
          <AttendanceSettings />
        </Route>
        <Route path={ROUTES.SETTINGS.PLAN_MANAGEMENT.ANY}>
          <PlanManagementSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.GENERAL.ANY}>
          <GeneralSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.SECURITY}>
          <SecuritySettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.USER_PREFERENCES}>
          <UserPreferences />
        </Route>
        <Route exact path={ROUTES.SETTINGS.SKILLS}>
          <SkillSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.CONTRACTS}>
          <ContractsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.EMPLOYEES.ANY}>
          <EmployeeSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.GROUPS}>
          <GroupsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.HELP_CENTER.ANY}>
          <HelpCenterSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.LIFECYCLE}>
          <LifecycleSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.DOCUMENTS}>
          <DocumentsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ORGANISATION.ANY}>
          <OrganisationSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.GOALS.ANY}>
          <GoalsSettings
            allRoute={ROUTES.SETTINGS.GOALS.ALL}
            targetTypesRoute={ROUTES.SETTINGS.GOALS.TARGET_TYPES}
            approvalProcessRoute={ROUTES.SETTINGS.GOALS.APPROVAL_PROCESS}
            roadmapsRoute={ROUTES.SETTINGS.GOALS.ROADMAPS}
            templatesRoute={ROUTES.SETTINGS.GOALS.TEMPLATES.LIST}
            rulesRoute={ROUTES.SETTINGS.GOALS.RULES.LIST}
            jiraIntegrationRoute={ROUTES.FEATURES.INTEGRATION.JIRA}
            backUrl={ROUTES.FORMS.COMPANY.GOALS.GENERAL}
            isOnboarding={false}
          />
        </Route>
        <Route exact path={ROUTES.SETTINGS.LOCATIONS}>
          <LocationSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ROLES.ANY}>
          <FunctionSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.BENEFITS.ANY}>
          <BenefitsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ANALYTICS}>
          <AnalyticsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.KPI}>
          <KPISettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.PERFORMANCE.ANY}>
          <PerformanceSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.TODO}>
          <TodoSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ROADMAPS}>
          <RoadmapSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ACCESS_MANAGEMENT}>
          <AccessManagementSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ENGAGEMENT.ANY}>
          <EngagementSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ENTITIES}>
          <EntitiesSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.COMMUNICATION}>
          <CommunicationSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.TIME_OFF.ANY}>
          <TimeOffSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOBS.ANY}>
          <JobsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.REQUISITIONS.ANY}>
          <RequisitionsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOB_POSTING.ANY}>
          <JobPostingSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.SCREENING}>
          <ScreeningSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.PAYROLL.ANY}>
          <PayrollSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING}>
          <CareerPageOnboardingSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.KEY_PERSONS}>
          <KeyPersonsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.CANDIDATES.ANY}>
          <CandidatesSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.CLOSE_ACCOUNT.ANY}>
          <CloseAccount />
        </Route>
        <Route exact path={ROUTES.SETTINGS.COMPENSATION.ANY}>
          <CompensationSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.CUSTOM_FIELDS}>
          <CustomFieldsSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOBS_INTEGRATIONS_ONBOARDING}>
          <CareersIntegrationsPage />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ONBOARDING.ANY}>
          <OnboardingSettings />
        </Route>
        <Route>
          <Page404 />
        </Route>
      </Switch>
      <Box id="approval-sidebar-portal" />
    </>
  )
}

export default Settings
