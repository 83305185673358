import React from 'react'
import { Flex, Token, VStack, Icon, Tag } from '@revolut/ui-kit'
import {
  PerformanceRating,
  ReviewDataInterface,
  ReviewDataSectionInterface,
  SkillCardInterface,
  ValueBasedCardInterface,
  ReviewSummaryDataInterface,
  Ratings,
  SkillSummary,
  SummarySkillCardInterface,
  DeliverableOptions,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
  performanceRatingToFinalGrade,
  PerformanceKPISection,
  GradeLabelMappingInterface,
} from '@src/interfaces/performance'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  performanceSummaryDeliverablesNameColumn,
  performanceSummaryExpectedRatingColumn,
  performanceSummarySkillsNameColumn,
  performanceSummarySkillReviewerColumn,
  performanceSummaryRatingColumnNewDesign,
  getComparedRatingSettings,
} from '@src/constants/columns/performanceSummary'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetSegmentedDeliverablesEnabled } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { useGetPerformanceSelector } from '@src/api/performance'
import { ContributorType } from '@src/interfaces/talent/performance'
import { TableNames } from '@src/constants/table'
import { QuickFilter } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Filter'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { GoalsSummaryCollapsableTable } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/GoalsSummaryCollapsableTable'
import { RatingWithTooltip } from './RatingWithTooltip'
import { CollapsableTableWrapper } from './CollapsableTableWrapper'
import { ExpectedSkillRatingInterface } from '@src/interfaces/probationReview'
import { SkillLevels } from '@src/interfaces/roles'
import { CalculatedDeliverablesRating } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/useCalculatedDeliverablesRating'

interface Props {
  reviewData: ReviewDataInterface | ReviewSummaryDataInterface
  reviewValues?: ReviewSummaryInterface
  isPreviousValues?: boolean
  hideExpectations?: boolean
  cycleId?: string | number
  activeFilters?: QuickFilter[]
  lineManagerId?: number
  userExpectedRatings: ExpectedSkillRatingInterface | undefined
  calculatedDeliverablesRating: CalculatedDeliverablesRating
}

export interface KpiRowInterface extends KpiInterface {
  rating_expectation: PerformanceRating
  recommended_rating: DeliverableOptions
}

const getRatingWithTooltip = (
  getGrade: boolean,
  rating?: PerformanceRating,
  ratings?: Ratings[],
  gradesMap?: GradesMapInterface,
  expectedLevel?: SkillLevels,
) => {
  return (
    <RatingWithTooltip
      getTitle={perfRating => {
        return getGrade
          ? gradesMap?.[performanceRatingToFinalGrade(perfRating)] || '-'
          : PerformanceRatingTitle[perfRating]
      }}
      rating={rating}
      ratings={ratings}
      expectedLevel={expectedLevel}
    />
  )
}

const getRatingColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
  expectedLevel: SkillLevels | undefined,
) => {
  const setting = getComparedRatingSettings(expectedLevel, data.rating)
  if (data.rating) {
    return (
      <>
        <Tag color={setting.color}>{PerformanceRatingTitle[data.rating]}</Tag>
        <Icon size={16} name={setting.icon} color={setting.color} />
      </>
    )
  }
  return '-'
}

const getGradeColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
  gradesMap: GradesMapInterface,
) => {
  return data.rating ? gradesMap[performanceRatingToFinalGrade(data.rating)] : '-'
}

const getRatingReviewerColumnValue = (activeFilters: QuickFilter[] | undefined) => {
  return (
    <Flex gap="s-4">
      {activeFilters
        ?.filter(filter => filter.avatar || filter.full_name)
        ?.map((filter, index) => (
          <UserWithAvatar
            size={24}
            key={index}
            uuid={filter.full_name}
            id={filter.key}
            full_name={filter.full_name}
            avatar={filter.avatar}
            compact
            asText
          />
        ))}
    </Flex>
  )
}

const getRatingSummaryColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
  expectedLevel: SkillLevels | undefined,
) => {
  return getRatingWithTooltip(
    false,
    data.rating || undefined,
    data.ratings,
    undefined,
    expectedLevel,
  )
}

const getGradeSummaryColumnValue = (
  data:
    | SkillSummary
    | SummarySkillCardInterface
    | KpiRowInterface
    | PerformanceKPISection,
  gradesMap: GradesMapInterface,
) => {
  return getRatingWithTooltip(true, data.rating || undefined, data.ratings, gradesMap)
}

const getDeliverablesRow: (
  gradesMap: GradesMapInterface,
  showSegmented?: boolean,
  isSummaryReview?: boolean,
) => RowInterface<SkillCardInterface | SummarySkillCardInterface> = (
  gradesMap,
  showSegmented,
  isSummaryReview,
) => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      insert: ({ data }) => (showSegmented ? data.name : 'Contribution and impact'),
      width: 100,
    },
    {
      ...performanceSummaryRatingColumnNewDesign,
      insert: ({ data }) =>
        'ratings' in data
          ? getGradeSummaryColumnValue(data, gradesMap)
          : getGradeColumnValue(data, gradesMap),
      title: isSummaryReview ? 'Grade' : 'Latest grade',
      width: 60,
      background: undefined,
    },
  ],
})

const getSkillsRow: (
  activeFilters: QuickFilter[] | undefined,
  isSummaryReview: boolean,
  expectedLevel: SkillLevels | undefined,
) => RowInterface<SkillCardInterface | SummarySkillCardInterface> = (
  activeFilters,
  isSummaryReview,
  expectedLevel,
) => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 50,
    },
    {
      ...performanceSummarySkillReviewerColumn,
      width: 50,
      insert: () => getRatingReviewerColumnValue(activeFilters),
    },
    {
      ...performanceSummaryRatingColumnNewDesign,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data, expectedLevel)
          : getRatingColumnValue(data, expectedLevel),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      background: undefined,
      width: 40,
    },
  ],
})

const getValuesRow: (
  activeFilters: QuickFilter[] | undefined,
  isSummaryReview: boolean,
  expectedLevel: SkillLevels | undefined,
) => RowInterface<
  SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
> = (activeFilters, isSummaryReview, expectedLevel) => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 50,
    },
    {
      ...performanceSummarySkillReviewerColumn,
      width: 50,
      insert: () => getRatingReviewerColumnValue(activeFilters),
    },
    {
      ...performanceSummaryRatingColumnNewDesign,
      background: undefined,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data, expectedLevel)
          : getRatingColumnValue(data, expectedLevel),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      width: 40,
    },
  ],
})

export const SummaryReviewCollapsableTables = ({
  cycleId,
  reviewData,
  reviewValues,
  isPreviousValues,
  hideExpectations,
  activeFilters,
  lineManagerId,
  userExpectedRatings,
  calculatedDeliverablesRating,
}: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const { gradesMap: newGradesMap } = useGetReviewGradesMap()
  const summaryValues = reviewValues || values
  const gradesMap = summaryValues.grade_label_mapping || newGradesMap
  const isSummaryReview = 'ratings' in reviewData
  const isSegmentedDeliverables = useGetSegmentedDeliverablesEnabled()
  const { data: cycles } = useGetPerformanceSelector(
    'reviews' in summaryValues
      ? summaryValues.reviews[0]?.reviewed_employee.id
      : summaryValues.reviewed_employee.id,
  )

  const employeeId =
    'reviews' in summaryValues
      ? summaryValues.reviews[0]?.reviewed_employee.id
      : summaryValues.reviewed_employee.id

  let deliverablesData
  if (reviewData.deliverables?.cards?.[0]) {
    deliverablesData = isSegmentedDeliverables
      ? reviewData.deliverables?.cards
      : [reviewData.deliverables.cards[0]]
  }

  const selectedPeriod = Array.isArray(cycles)
    ? cycles.find(({ id }) => id.toString() === cycleId)
    : null

  const skillsData = reviewData.functional_skills?.cards
  const managerSkillsData = reviewData.manager_skills?.cards
  const managerValuesData = reviewData.manager_values?.cards
  const cultureValuesData = reviewData.culture_values?.cards
  const cultureSkillsData = reviewData.culture_skills?.cards

  const getAdjustedTitle = (title: string) => {
    if (isPreviousValues) {
      const cycleName =
        'reviews' in summaryValues
          ? summaryValues.reviews[0]?.cycle?.name
          : summaryValues.cycle?.name
      return `${title}${cycleName ? ` (${cycleName})` : ''}`
    }
    return title
  }

  const functionalSkillsRatings = reviewData.functional_skills?.ratings
  const managerSkillsRatings = reviewData.manager_skills?.ratings

  const cultureValuesRatings = reviewData.culture_values?.ratings
  const cultureSkillsValuesRatings = reviewData.culture_skills?.ratings
  const managerValuesRatings = reviewData.manager_values?.ratings
  return (
    <VStack space="s-24">
      {!isPreviousValues && selectedPeriod && (
        <GoalsSummaryCollapsableTable
          isSummaryReview={isSummaryReview}
          calculatedDeliverablesRating={calculatedDeliverablesRating}
          employee={{
            id: employeeId,
          }}
          lineManagerId={lineManagerId}
          selectedPeriod={selectedPeriod}
          reviewSummary={{
            team: summaryValues.team,
            grade_label_mapping:
              summaryValues.grade_label_mapping as GradeLabelMappingInterface,
            reviewed_employee_type:
              summaryValues.reviewed_employee_type as ContributorType,
          }}
        />
      )}

      {deliverablesData && !isPreviousValues && isSegmentedDeliverables && (
        <CollapsableTableWrapper
          headerTitle="Goals"
          headerGrade={
            reviewData.deliverables?.rating
              ? performanceRatingToFinalGrade(reviewData.deliverables?.rating)
              : undefined
          }
          completedReviews={isSummaryReview}
          count={deliverablesData.length}
          icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
          ratings={calculatedDeliverablesRating.ratings}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getDeliverablesRow(gradesMap, isSegmentedDeliverables, isSummaryReview)}
            count={deliverablesData.length}
            data={deliverablesData}
            hideCountAndButtonSection
          />
        </CollapsableTableWrapper>
      )}

      {skillsData && (
        <CollapsableTableWrapper
          icon={<Icon name="Palette" color={Token.color.onAccent} size={24} />}
          headerTitle={getAdjustedTitle('Skills')}
          testId="card-table-Functional skills"
          headerRating={reviewData.functional_skills?.rating || undefined}
          ratings={functionalSkillsRatings}
          completedReviews={isSummaryReview}
          count={skillsData.length}
          noHeadersOffset
        >
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummarySkills}
            row={getSkillsRow(
              activeFilters,
              isSummaryReview,
              userExpectedRatings?.expected_functional_skills_rating ||
                userExpectedRatings?.expected_overall_rating,
            )}
            count={skillsData.length}
            data={skillsData}
            hideHeader
            hideCountAndButtonSection
            hiddenCells={{
              [performanceSummaryExpectedRatingColumn.idPoint]: hideExpectations,
            }}
          />
        </CollapsableTableWrapper>
      )}

      {managerSkillsData && (
        <CollapsableTableWrapper
          icon={<Icon name="Palette" color={Token.color.onAccent} size={24} />}
          headerTitle={getAdjustedTitle('Manager skills')}
          headerRating={reviewData.manager_skills?.rating || undefined}
          completedReviews={isSummaryReview}
          count={managerSkillsData.length}
          ratings={managerSkillsRatings}
          noHeadersOffset
        >
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummaryManagerSkills}
            row={getSkillsRow(
              activeFilters,
              isSummaryReview,
              userExpectedRatings?.expected_overall_rating,
            )}
            hiddenCells={{
              [performanceSummaryExpectedRatingColumn.idPoint]: true,
            }}
            count={managerSkillsData.length}
            data={managerSkillsData}
            hideHeader
            hideCountAndButtonSection
          />
        </CollapsableTableWrapper>
      )}

      {managerValuesData && (
        <CollapsableTableWrapper
          count={managerValuesData.length}
          icon={<Icon name="Heart" color={Token.color.onAccent} size={24} />}
          headerTitle={getAdjustedTitle('Management values')}
          headerRating={reviewData.manager_values?.rating || undefined}
          completedReviews={isSummaryReview}
          ratings={managerValuesRatings}
          noHeadersOffset
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(
              activeFilters,
              isSummaryReview,
              userExpectedRatings?.expected_overall_rating,
            )}
            count={managerValuesData.length}
            data={managerValuesData}
            hideCountAndButtonSection
            hideHeader
          />
        </CollapsableTableWrapper>
      )}

      {cultureValuesData && (
        <CollapsableTableWrapper
          count={cultureValuesData.length}
          icon={<Icon name="Heart" color={Token.color.onAccent} size={24} />}
          headerTitle={getAdjustedTitle('Values')}
          headerRating={reviewData.culture_values?.rating || undefined}
          completedReviews={isSummaryReview}
          ratings={cultureValuesRatings}
          noHeadersOffset
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(
              activeFilters,
              isSummaryReview,
              userExpectedRatings?.expected_overall_rating,
            )}
            count={cultureValuesData.length}
            data={cultureValuesData}
            hideCountAndButtonSection
            hideHeader
          />
        </CollapsableTableWrapper>
      )}

      {cultureSkillsData && (
        <CollapsableTableWrapper
          icon={<Icon name="Heart" color={Token.color.onAccent} size={24} />}
          headerTitle={getAdjustedTitle('Value')}
          headerRating={reviewData.culture_skills?.rating || undefined}
          completedReviews={isSummaryReview}
          count={cultureSkillsData.length}
          ratings={cultureSkillsValuesRatings}
          noHeadersOffset
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(
              activeFilters,
              isSummaryReview,
              userExpectedRatings?.expected_culture_skills_rating ||
                userExpectedRatings?.expected_overall_rating,
            )}
            count={cultureSkillsData.length}
            data={cultureSkillsData}
            hideHeader
            hideCountAndButtonSection
          />
        </CollapsableTableWrapper>
      )}
    </VStack>
  )
}
