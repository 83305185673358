import React, { PropsWithChildren, useEffect } from 'react'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  TextSkeleton,
  Token,
  TransitionCollapse,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import {
  convertOperators,
  getGradeColor,
} from '@src/pages/Forms/Candidate/Performance/utils'
import upperFirst from 'lodash/upperFirst'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { PerformanceRating } from '@src/interfaces/performance'

type PerformanceSectionType = 'delivery' | 'skill' | 'value'

const PerformanceSectionAvatar = ({ type }: { type: PerformanceSectionType }) => {
  switch (type) {
    case 'delivery':
      return <Avatar useIcon="Target" />
    case 'skill':
      return <Avatar useIcon="Palette" />
    case 'value':
      return <Avatar useIcon="Heart" />
    default:
      return null
  }
}

type PerformanceSectionProps = {
  collapse: boolean
  grade?: PerformanceRating | null
  loading?: boolean
  title?: React.ReactNode
  type: PerformanceSectionType
}

export const PerformanceSection = ({
  collapse,
  grade,
  loading,
  title,
  type,
  children,
}: PropsWithChildren<PerformanceSectionProps>) => {
  const [collapsed, toggleCollapsed] = useToggle()
  useEffect(() => {
    toggleCollapsed(collapse)
  }, [collapse])
  const { theme } = useAppTheme()
  const backgroundColor =
    theme === 'dark' ? Token.color.popoverBackground : Token.color.groupedBackground
  return (
    <VStack bg={backgroundColor} radius={Token.radius.widget}>
      <HStack
        align="center"
        p="s-16"
        onClick={() => {
          toggleCollapsed(!collapsed)
        }}
        data-testid={`performance-section-${type}-header`}
      >
        <Icon name={collapsed ? 'ChevronRight' : 'ChevronDown'} />
        <Box width="100%">
          <Flex justifyContent="space-between" alignItems="center" px="s-16">
            <HStack gap="s-6" align="center">
              <PerformanceSectionAvatar type={type} />
              <Text variant="emphasis1" whiteSpace="nowrap">
                {title}
              </Text>
            </HStack>
            {loading ? (
              <TextSkeleton width="120px" />
            ) : (
              <Text
                color={getGradeColor(convertOperators(grade, '', ''))}
                variant="heading3"
                whiteSpace="nowrap"
              >
                {grade ? convertOperators(upperFirst(grade)) : 'Not assessed'}
              </Text>
            )}
          </Flex>
        </Box>
      </HStack>
      <TransitionCollapse in={!collapsed}>
        <Box data-testid={`performance-section-${type}-content`}>{children}</Box>
      </TransitionCollapse>
    </VStack>
  )
}
