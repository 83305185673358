import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { isInDateRange } from '@src/utils/timezones'
import { compareAsc, compareDesc, endOfDay, startOfDay } from 'date-fns'
import { notReachable } from '@src/utils/notReachable'
import { CycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

export class TimelineModel {
  // TODO: temporary hardcoded on FE, use BE selector https://revolut.atlassian.net/browse/REVC-6851
  private static readonly stagesOrder: ReviewCycleStage[] = [
    ReviewCycleStage.DepartmentGoals,
    ReviewCycleStage.TeamGoals,
    ReviewCycleStage.Review,
    ReviewCycleStage.Calibration,
    ReviewCycleStage.ManagersPublish,
    ReviewCycleStage.EmployeesPublish,
  ]

  private static readonly defaultStage = ReviewCycleStage.DepartmentGoals

  static readonly getAvailableStages = (): ReviewCycleStage[] => TimelineModel.stagesOrder

  static readonly getCurrentStage = (cycle: ReviewCyclesInterface): ReviewCycleStage => {
    if (CycleModel.isManual(cycle)) {
      return cycle.current_stage ?? TimelineModel.defaultStage
    }

    const ongoingStages = TimelineModel.getAvailableStages().filter(stage =>
      TimelineModel.isOngoingStage(cycle, stage),
    )

    const [firstStage] = ongoingStages

    return firstStage ?? TimelineModel.defaultStage
  }

  static readonly getCalibrationStageStartDay = (
    cycle: ReviewCyclesInterface,
  ): Date | null => {
    const [firstCalibrationDay] = [
      cycle.department_owner_calibration_start_day,
      cycle.head_of_function_calibration_start_day,
    ]
      .filter(Boolean)
      .map<Date>(dateString => new Date(dateString))
      .sort(compareAsc)

    return firstCalibrationDay ?? null
  }

  static readonly getReviewStageEndDay = (cycle: ReviewCyclesInterface): Date | null => {
    const [lastReviewDay] = [
      cycle.self_and_peer_reviews_last_day,
      cycle.managers_reviews_last_day,
    ]
      .filter(Boolean)
      .map<Date>(dateString => new Date(dateString))
      .sort(compareDesc)

    return lastReviewDay ?? null
  }

  static readonly getStageStartDay = (
    cycle: ReviewCyclesInterface,
    stage: ReviewCycleStage,
  ): Date | null => {
    const {
      department_kpi_period_start_day: depGoalsStartDay,
      team_kpi_period_start_day: teamGoalsStartDay,
      review_period_start_day: reviewStartDay,
      managers_publishing_day: managersPublishDay,
      reviews_publishing_day: employeesPublishDay,
    } = cycle

    switch (stage) {
      case ReviewCycleStage.DepartmentGoals:
        return depGoalsStartDay ? startOfDay(new Date(depGoalsStartDay)) : null

      case ReviewCycleStage.TeamGoals:
        return teamGoalsStartDay ? startOfDay(new Date(teamGoalsStartDay)) : null

      case ReviewCycleStage.Review:
        return reviewStartDay ? new Date(reviewStartDay) : null

      case ReviewCycleStage.Calibration:
        return TimelineModel.getCalibrationStageStartDay(cycle)

      case ReviewCycleStage.ManagersPublish:
        return managersPublishDay ? new Date(managersPublishDay) : null

      case ReviewCycleStage.EmployeesPublish:
        return employeesPublishDay ? new Date(employeesPublishDay) : null

      default:
        return notReachable(stage)
    }
  }

  static readonly getStageEndDay = (
    cycle: ReviewCyclesInterface,
    stage: ReviewCycleStage,
  ): Date | null => {
    const {
      department_kpi_period_end_day: depGoalsEndDay,
      team_kpi_period_end_day: teamGoalsEndDay,
      head_of_function_and_department_last_calibration_day: calibrationEndDay,
      managers_publishing_day: managersPublishEndDay,
      reviews_publishing_day: employeesPublishEndDay,
    } = cycle

    switch (stage) {
      case ReviewCycleStage.DepartmentGoals:
        return depGoalsEndDay ? endOfDay(new Date(depGoalsEndDay)) : null

      case ReviewCycleStage.TeamGoals:
        return teamGoalsEndDay ? endOfDay(new Date(teamGoalsEndDay)) : null

      case ReviewCycleStage.Review:
        return TimelineModel.getReviewStageEndDay(cycle)

      case ReviewCycleStage.Calibration:
        return calibrationEndDay ? new Date(calibrationEndDay) : null

      case ReviewCycleStage.ManagersPublish:
        return managersPublishEndDay ? endOfDay(new Date(managersPublishEndDay)) : null

      case ReviewCycleStage.EmployeesPublish:
        return employeesPublishEndDay ? endOfDay(new Date(employeesPublishEndDay)) : null

      default:
        return notReachable(stage)
    }
  }

  static readonly isOngoingStage = (
    cycle: ReviewCyclesInterface,
    stage: ReviewCycleStage,
  ): boolean => {
    if (CycleModel.isManual(cycle)) {
      return cycle.current_stage === stage
    }

    const today = new Date()
    const startDay = TimelineModel.getStageStartDay(cycle, stage)
    const endDay = TimelineModel.getStageEndDay(cycle, stage)

    if (!startDay || !endDay) {
      return false
    }

    return isInDateRange(startDay, endDay, today)
  }
}
