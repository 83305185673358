import React, { useRef } from 'react'
import { Flex, Text, Token, VStack } from '@revolut/ui-kit'
import {
  KeeperGradeToString,
  KeeperQuestionInterface,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewInterface,
  ReviewSummaryDataInterface,
  ReviewViewDataInterface,
} from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceSummaryBarRaiserReviewerColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { BarRaiserBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserBanner'
import { BarRaiserSummaryJustification } from './BarRaiserSummaryJustification'
import { PeerReviewsWithTooltip } from './PeerReviewsWithTooltip'

interface Props {
  data: ReviewSummaryDataInterface | ReviewDataInterface
  completedReviews?: boolean
  hideBanner?: boolean
}

export interface RecommendationQuestionReviewInterface {
  recommendation: ManagerRecommendationInterface
  type: RecommendationTypes
}

export interface RecommendationQuestionInterface extends KeeperQuestionInterface {
  reviews: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}

const getRow: (
  completedReviews: boolean,
  isRecommendationExtraSection: boolean,
) => RowInterface<RecommendationQuestionInterface> = (
  completedReviews,
  isRecommendationExtraSection,
) => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: '',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const lmReview = isRecommendationExtraSection
          ? data.reviews?.find(
              review => review.recommendation.relation === ReviewerRelation.LineManager,
            )
          : data.reviews?.find(review => review.type === 'lm')
        const questionValue = isRecommendationExtraSection
          ? lmReview?.recommendation?.recommendation?.[data.questionIdx].value
          : lmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value

        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'

        return lmReview ? gradeValue : '-'
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const fmReview = isRecommendationExtraSection
          ? data.reviews?.find(
              review =>
                review.recommendation.relation === ReviewerRelation.FunctionalManager,
            )
          : data.reviews?.find(review => review.type === 'fm')

        const questionValue = isRecommendationExtraSection
          ? fmReview?.recommendation?.recommendation?.[data.questionIdx].value
          : fmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'

        return fmReview ? gradeValue : '-'
      },
      title: 'FM',
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const peerReviews = data.reviews?.filter(review => review.type === 'peer')
        const questionValue =
          peerReviews?.[0]?.recommendation.keeper_test_section.questions[data.questionIdx]
            .value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return completedReviews ? (
          <PeerReviewsWithTooltip reviews={peerReviews} questionIdx={data.questionIdx} />
        ) : (
          gradeValue
        )
      },
      title: 'Peer',
      width: 60,
    },
  ],
})

const getRecommendations = (
  data: ReviewSummaryDataInterface | ReviewViewDataInterface,
) => {
  const lmRecommendation = data.line_manager_extra_section
  const fmRecommendation = data.functional_manager_extra_section
  const peerRecommendations = data.peer_extra_sections
  const probationPipRecommendation = data.recommendation_extra_sections

  if (
    !lmRecommendation &&
    !fmRecommendation &&
    !peerRecommendations?.length &&
    !probationPipRecommendation?.length
  ) {
    return null
  }

  return [
    ...(lmRecommendation
      ? [
          {
            recommendation: lmRecommendation,
            type: RecommendationTypes.LM,
          },
        ]
      : []),
    ...(fmRecommendation
      ? [
          {
            recommendation: fmRecommendation,
            type: RecommendationTypes.FM,
          },
        ]
      : []),
    ...(peerRecommendations
      ? peerRecommendations.map(item => ({
          recommendation: item,
          type: RecommendationTypes.PEER,
        }))
      : []),
    ...(probationPipRecommendation
      ? probationPipRecommendation.map(item => ({
          recommendation: item,
          type: RecommendationTypes.PIP,
        }))
      : []),
  ]
}

export const BarRaiserSummary = ({
  data,
  completedReviews = false,
  hideBanner,
}: Props) => {
  const barRaiserRef = useRef<HTMLButtonElement>(null)
  const recommendations = getRecommendations(data)
  if (!recommendations?.length) {
    return null
  }

  const isRecommendationExtraSection = !!data.recommendation_extra_sections?.length

  const getJustification = (
    recommendation: ManagerRecommendationInterface,
    relation: ReviewerRelation,
  ) => {
    if (
      !recommendation?.employee_project_performance.justification ||
      !recommendation?.reviewer
    ) {
      return null
    }

    return {
      value: recommendation.employee_project_performance.justification,
      review: {
        ...recommendation,
        reviewer_relation: relation,
      } as unknown as ReviewInterface,
    }
  }

  const fmJustification = data.functional_manager_extra_section
    ? getJustification(
        data.functional_manager_extra_section,
        ReviewerRelation.FunctionalManager,
      )
    : null
  const lmJustification = data.line_manager_extra_section
    ? getJustification(data.line_manager_extra_section, ReviewerRelation.LineManager)
    : null

  const justifications = [lmJustification, fmJustification].filter(Boolean)

  const recentRecommendationIndex = data.recommendation_extra_sections?.length
    ? data.recommendation_extra_sections.length - 1
    : 0
  const tableData = isRecommendationExtraSection
    ? recommendations[recentRecommendationIndex].recommendation.recommendation?.map(
        (question, idx) => ({
          ...question,
          name: question.title,
          reviews: recommendations,
          questionIdx: idx,
        }),
      )
    : recommendations[0].recommendation.keeper_test_section.questions.map(
        (question, idx) => ({
          ...question,
          reviews: recommendations,
          questionIdx: idx,
        }),
      )

  return (
    <VStack space="s-16" ref={barRaiserRef}>
      {!hideBanner && <BarRaiserBanner />}
      <Text variant="h6" color={Token.color.greyTone50}>
        Bar raiser
      </Text>
      {tableData?.length && (
        <>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable
              name={TableNames.PerformanceSummaryBarRaiser}
              row={getRow(completedReviews, isRecommendationExtraSection)}
              count={tableData.length}
              data={tableData}
              hideCountAndButtonSection
            />
          </Flex>
          <BarRaiserSummaryJustification justifications={justifications} />
        </>
      )}
    </VStack>
  )
}
