import React, { useState } from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PerformanceOverview } from '@src/pages/Forms/Candidate/Performance/PerformanceOverview'
import { Token, VStack, Widget } from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'

type PerformanceProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
}

export const Performance = ({ candidate, round }: PerformanceProps) => {
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds([])
  }
  return (
    <>
      <PageBody maxWidth={Token.breakpoint.lg}>
        <VStack gap="s-16">
          <PerformanceOverview candidate={candidate} round={round} />
          <Widget pb="s-16">
            <PerformanceSummary
              round={round}
              onViewScorecard={feedbackIds => {
                setInterviewFeedbackIds(feedbackIds)
              }}
            />
          </Widget>
        </VStack>
      </PageBody>
      {!!interviewFeedbackIds?.length && (
        <ScorecardSidebar
          interviewFeedbackIds={interviewFeedbackIds}
          round={round}
          onClose={onCloseInterviewFeedbacks}
        />
      )}
    </>
  )
}
