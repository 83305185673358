import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { CycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { isSameDay } from 'date-fns'
import { formatDate, formatPeriod } from '@src/utils/format'

export const formatStagePeriod = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
  hideYear: boolean = true,
): string | undefined => {
  if (CycleModel.isManual(cycle)) {
    return undefined
  }

  const startDay = TimelineModel.getStageStartDay(cycle, stage)
  const endDay = TimelineModel.getStageEndDay(cycle, stage)

  if (!startDay || !endDay) {
    return undefined
  }

  if (isSameDay(startDay, endDay)) {
    return formatDate(endDay, 'd MMM')
  }

  return formatPeriod(startDay, endDay, hideYear)
}
