import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import {
  ColoredPercent,
  getFunctionNIPSColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import React from 'react'
import AuditCircles from '../../components/ColumnInserts/AuditCircles/AuditCircles'
import { TeamInterface } from '@src/interfaces/teams'
import { formatMoney, formatNumber } from '@src/utils/format'
import CultureCircles from '@components/ColumnInserts/CultureCircles/CultureCircles'
import { Token } from '@revolut/ui-kit'
import { CommunicationIcons } from '@src/features/CommunicationGroups/CommunicationIcons'
import { FunctionInterface } from '@src/interfaces/functions'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import Table from '@components/TableV2/Table'
import { getDefaultApprovalStatusVariant } from '@components/TableV2/Cells/StatusCell'

export const departmentGenericNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentNameIconColumn: ColumnInterface<DepartmentInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data, url }) => (
    <Table.EntityCell useIcon={data.icon || 'Bank'} url={url}>
      {data.name}
    </Table.EntityCell>
  ),
}

export const departmentNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentHeadcountColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  colors: (data: DepartmentInterface | TeamInterface) =>
    data.headcount ? Token.color.foreground : Token.color.greyTone20,
  title: 'Headcount',
}

export const nipsColumn: ColumnInterface<{ nips?: number }> = {
  type: CellTypes.insert,
  idPoint: 'nips',
  dataPoint: 'nips',
  sortKey: 'nips',
  filterKey: 'nips',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.nips !== undefined) {
      const NIPS = formatNumber(data.nips * 100, 1)
      return <ColoredPercent percent={NIPS} color={getFunctionNIPSColor(NIPS)} />
    }
    return null
  },
  title: 'Nips',
  headerTooltip: (
    <TooltipContainer>
      Net Individual Performance Score (NIPS) = %Exceptional + %Exceeding + %Performing -
      %Developing - %Unsatisfactory. These stats are calculated using most recent
      performance grades and don’t depend on the chosen cycle
    </TooltipContainer>
  ),
}

export const departmentRequisitionsColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.text,
  idPoint: 'requisition_remaining_headcount',
  dataPoint: 'requisition_remaining_headcount',
  sortKey: 'requisition_remaining_headcount',
  filterKey: 'requisition_remaining_headcount',
  filterType: FilterType.range,
  colors: (data: DepartmentInterface | TeamInterface) =>
    data.requisition_remaining_headcount
      ? Token.color.foreground
      : Token.color.greyTone20,
  selectorsKey: selectorKeys.none,
  title: 'Hiring',
}

export const departmentPerformanceColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  insert: ({ data }) => <ColoredPercent percent={data.performance * 100} />,
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.performance,
  title: 'Performance',
}

export const getDepartmentKPIColumn: (
  goalsEnabled?: boolean,
  isNewTable?: boolean,
) => ColumnInterface<DepartmentInterface | TeamInterface> = (
  goalsEnabled,
  isNewTable,
) => {
  const key = goalsEnabled ? 'goals_progress_percent' : 'kpi_performance_percent'
  const sortFilterKey = goalsEnabled
    ? 'goals_progress_percent'
    : 'kpi_performance_percent'
  return {
    type: CellTypes.insert,
    idPoint: key,
    insert: ({ data }) => {
      const progress = goalsEnabled
        ? data.goals_progress_percent || 0
        : data.kpi_performance_percent

      if (isNewTable) {
        return <Table.ProgressCell value={progress} />
      }
      return <ColoredPercent percent={progress * 100} />
    },
    dataPoint: key,
    sortKey: sortFilterKey,
    filterKey: sortFilterKey,
    filterType: FilterType.percentRange,
    selectorsKey: goalsEnabled ? selectorKeys.none : selectorKeys.kpi_performance_percent,
    title: goalsEnabled ? 'Goals' : 'KPI',
  }
}

export const departmentRoadmapColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'roadmap_progress_percent',
  insert: ({ data }) => <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
  dataPoint: 'roadmap_progress_percent',
  sortKey: 'roadmap_progress_percent',
  filterKey: 'roadmap_progress_percent',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.roadmap_progress_percent,
  title: 'Roadmap',
}

export const departmentAuditColumn: ColumnInterface<DepartmentInterface | TeamInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'audit',
    dataPoint: 'audit',
    sortKey: 'total_issues',
    filterKey: null,
    insert: ({ data }) => {
      return <AuditCircles data={data} />
    },
    selectorsKey: selectorKeys.none,
    title: 'Issues',
  }

export const departmentBudgetColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'total_current_spent',
  dataPoint: 'total_current_spent',
  sortKey: 'total_current_spent',
  filterKey: 'total_current_spent',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.team,
  insert: ({ data }) => {
    if (typeof data.total_current_spent !== 'number') {
      return '-'
    }

    return formatMoney(data.total_current_spent, data.budget_currency)
  },
  textAlign: 'right',
  title: 'Cost/month',
}

export const departmentStatusColumn: ColumnInterface<DepartmentInterface> = {
  type: CellTypes.insert,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.department_approval_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.approval_status}
      variant={getDefaultApprovalStatusVariant(data.approval_status)}
    />
  ),
}

export const departmentCultureColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'culture_enps',
  dataPoint: 'culture_enps',
  sortKey: 'percentile_score',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <CultureCircles data={data} />,
  title: 'Culture',
}

export const departmentNameRevolutersColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'team__department__name',
  filterKey: 'team__department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentCommunicationColumn: ColumnInterface<
  DepartmentInterface | TeamInterface | FunctionInterface
> = {
  type: CellTypes.insert,
  idPoint: 'communication_group.id',
  dataPoint: 'communication_group.id',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => <CommunicationIcons group={data.communication_group} />,
  selectorsKey: selectorKeys.none,
  title: 'Communication',
}
